import React from "react";
import { PhoneIcon } from "@heroicons/react/outline"

const Navbar = () => (
<div className="sticky top-0 z-50 bg-white border border-b border-gray-300">
<div className="container py-4 grid grid-cols-2">
    <h1>CDC Transporation</h1>
    <p className="text-right my-auto text-semibold"><PhoneIcon className="h-6 w-6 inline mr-1" /><a href="tel:2165019651">(216) 501-9651</a></p>
</div>
</div>
)
export default Navbar;